import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/views/layout'
import Home from '@/views/layout/home.vue'
import Category from '@/views/layout/category.vue'
import Cart from '@/views/layout/cart.vue'
import User from '@/views/layout/user.vue'
// import store from '@/store'

const Login = () => import('@/views/login')
const Myorder = () => import('@/views/myorder')
const Pay = () => import('@/views/pay')
const Prodetail = () => import('@/views/prodetail')
const Search = () => import('@/views/search')
const List = () => import('@/views/search/list.vue')
const ContributionRank = () => import('@/views/ContributionRank/index.vue')

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    // { path: '/login', component: Login },
    { path: '/ContributionRank', component: ContributionRank },
    // {
    //   path: '/',
    //   component: Layout,
    //   redirect: '/home',
    //   children: [
    //     { path: '/home', component: Home },
    //     { path: '/category', component: Category },
    //     { path: '/cart', component: Cart },
    //     { path: '/user', component: User }
    //   ]
    // },
    // { path: '/myorder', component: Myorder },
    // { path: '/pay', component: Pay },
    // // 动态路由传参，确定商品详情是哪个
    // { path: '/prodetail/:id', component: Prodetail },
    // { path: '/search', component: Search },
    // { path: '/list', component: List }
  ]
})
// 导航全局守卫
// const authUrls = ['/pay', '/myorder']
router.beforeEach((to, from, next) => {
  // if (!authUrls.includes(to.path)) {
  //   next()
  // } else {
  //   if (store.getters.token) {
  next()
  //   } else {
  //     next('/login')
  //   }
  // }
})
export default router
